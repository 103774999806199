<template>
  <div class="row">
    <div class="col-12">
      <template>
        <component
          :is="component"
          :patrolInfo.sync="patrolInfo"
        />
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'process-manual',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          title: '',
        }
      },
    },
  },
  data() {
    return {
      editable: true,
      patrolInfo: {
        patrolTypeCd: 'PTC0000002',
        title: '관리감독자 업무일지 점검 항목',
      },
      component: null,
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.component = () => import('@/pages/sai/patrol/patrolMaster.vue');
    },
    reset() {
      Object.assign(this.$data.patrolInfo, this.$options.data().patrolInfo);
    },
  }
};
</script>